import React from "react";
import { MdmInput } from './services/types';
import { getInitialState, extendInput, getOutput } from './services/MdmTool';
import { MdmStorage } from './services/MdmStorage';
import { MdmFullContextType } from './services/types'

function reducer(state: any, action: any) {
    switch (action.type) {
        case 'SET_INPUT': {
            if (action.payload) {
                const s: MdmInput = action.payload;
                MdmStorage.set(s);
                return s;
            }
        }
    }
    return state
}

export const useInputContext = (area: string): MdmFullContextType => {
    const [input, dispatch] = React.useReducer(reducer, getInitialState(), MdmStorage.get);
    console.log('useInputContext', area, input);
    const ext = extendInput(input);
    const output = getOutput(input);
    const onChange = (field: string) => {
        return (val: any) => {
            console.log('field:', field, 'val: ', val);
            dispatch({ type: 'SET_INPUT', payload: {...input, [field]: val} }); 
        };
    }
    return { input, ext, output, onChange };
};