import React from 'react';
import styled from 'styled-components';
import { MdmMonthlyOutput } from './../services/types';

import { toPounds } from './../services/Formats';
import TextInput from './Elements/TextInput';
import RangerInput from './Elements/RangerInput';
import { Info, InfoPoint } from './Info';

const EntryWrapper = styled.div`
    max-width: 885px;
    margin: 0px auto;

    h3 { 
        font-size: 1.75em; 
        line-height: 2em;
        margin-bottom: 40px; 
        border-bottom: 1px #666 solid;
    }
    h4 { 
        font-size: 1.33em;
        font-weight: 800;
        color: #424242;
    }
    p {
        font-size: 1.1em;
        color: #424242;
    }
    .row {
        display: flex;
        align-items: center;
    }
    .col {
        flex: 1;
        margin: 10px 0px;
    }
    .col:last-child {
        text-align: right;
    }
    .col .comment {
        font-size: 0.75rem;
        color: #666;
    }
    .row .label,
    .row button.info-wrapper .label {
        font-family: 'Nunito Sans', arial;
        font-size: 1.25rem;
        font-weight: 800;
        color: #666;
        margin-top: 24px;
    }
    .row .col.value {
        font-size: 2em;
        font-weight: 800;
        flex: 0;
        min-width: 200px;
        padding-top: 1rem;
    }

    .row .label .comment {
        font-size: 0.66rem;
        font-weight: normal;
        color: #666;
    }

    .row-total {
        margin-top: 50px;
        background: #ff7600;
        padding: 40px 40px;
        display: flex;
        align-items: center;
    }
    .row-multi-total {
        margin-top: 0px;
        margin-bottom: 30px;
        background: transparent;
        padding: 40px 40px;
        display: flex;
        align-items: center;
    }
    .col-total:last-child {
        text-align: right;
    }
    .col-total {
        color: white;
        font-size: 2em;
        font-weight: 800;
    }
    .col-total.l {
    }
    .col-total.r {
        flex: 1;
        width: 15%;
    }
    .row-multi-total .col-total{
        color: #424242;
    }

    .phases-row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    .phases-row.hdr {
        font-size: 1.33em;
        font-weight: 600;
        border-bottom: 1px #888 solid;
        margin-bottom: 20px;
    }
    .phases-col {
        flex: 1;
        margin: 10px 0px;
    }
    .phases-col:last-child {
        padding-left: 20px;
    }
    .phases-col.label,
    .phases-col.label button { 
        margin-top:5px;
        font-size: 1.15rem;
        font-weight: 800;
        color: #666;
        outline: none;
    }
    .phases-col.label .comment {
        font-size: 0.75rem;
        font-weight: normal;
        color: #666;
        padding-right: 20px;
    }
    .phases-col.accent {
        color: #ff7600;
        font-size: 1.33em;
        font-weight: 800;
        text-align: center;
    }

    button.info-wrapper {
        background: transparent;
        border: none;
        text-align: left;
        display: block;
        padding: 0px;
        outline: none !important;
    }

`;

export const Entry: React.FC<any> = (props: any) => {
    const { input, ext, output, onChange } = props;
    const { multiple } = props;

    // if multiple, return average + x, is single, return text without change
    const avg = (x: string) => {
        return (multiple) ? ' Average ' + x.toLowerCase() + ' ' : ' ' + x + ' ';
    };

    const getMonth = (index: number) => {
        return output.Months.filter((m :MdmMonthlyOutput) => (m.Index === index))
    };
    return (
        <EntryWrapper>
            {multiple ? (
                <div className="row">
                    <div className="col label">Number of new hires</div>
                    <div className="col">
                        <RangerInput
                            name='NumHire'
                            min={1} max={10}
                            withLabel={true}
                            value={input.NumHire} onChange={onChange('NumHire')}
                        />
                    </div>
                </div>
            ): null}
            <h3>Part 1: Business costs for individual hire</h3>
            <div className="row">
                <div className="col label">
                    {avg('Annual')} employee salary
                </div>
                <div className="col c">
                    <RangerInput
                        name='Salary'
                        min={0} max={500000}
                        withLabel={true} prefix="£"
                        value={input.Salary} onChange={onChange('Salary')}
                    />
                </div>
                <div className="col value">{toPounds(input.Salary)}</div>
            </div>
            <div className="row">
                <div className="col">
                    <Info comment="as a percentage of salary">
                        Employee benefits and pension
                    </Info>
                </div>
                <div className="col c">
                    <RangerInput
                        name='PercEmpBenefits'
                        min={0} max={50}
                        withLabel={true} suffix="%"
                        value={input.PercEmpBenefits} onChange={onChange('PercEmpBenefits')}
                    />
                </div>
                <div className="col value">{toPounds(ext.EmpBenefits)}</div>
            </div>
            <div className="row">
                <div className="col label">NI contributions</div>
                <div className="col c">
                    <RangerInput
                        name='PercTax'
                        min={0} max={20}
                        withLabel={true} suffix="%"
                        value={input.PercTax} onChange={onChange('PercTax')}
                    />
                </div>
                <div className="col value">{toPounds(ext.Tax)}</div>
            </div>
            <div className="row">
                <div className="col label">Annual manager's salary</div>
                <div className="col label">% of manager's salary<br />attributable to employee</div>
                <div className="col value">&nbsp;</div>
            </div>
            <div className="row">
                <div className="col">
                    <TextInput
                        name='TotalManSalary'
                        type="currency" color="#ff7600" prefixes="£"
                        value={input.TotalManSalary}
                        onChange={onChange('TotalManSalary')} />
                </div>
                <div className="col">
                    <RangerInput
                        name='PercManSalary'
                        min={0} max={50}
                        withLabel={true} suffix="%"
                        value={input.PercManSalary} onChange={onChange('PercManSalary')}
                    />
                </div>
                <div className="col value">{toPounds(ext.ManSalary)}</div>
            </div>
            <div className="row">
                <div className="col">
                    <Info
                        comment="These would include ongoing
                        centralised resources like HR.
                        IT, Training functions as a % of employee salary."
                    >
                        {avg('Ongoing')} management costs
                    </Info>
                </div>
                <div className="col c">
                    <RangerInput
                        name='PercOngManCost'
                        min={0} max={50}
                        withLabel={true} suffix="%"
                        value={input.PercOngManCost} onChange={onChange('PercOngManCost')}
                    />
                </div>
                <div className="col value">{toPounds(ext.OngManCost)}</div>
            </div>
            <div className="row">
                <div className="col label">{avg('Recruitment')} fees</div>
                <div className="col c">
                    <RangerInput
                        name='PercRecrFees'
                        min={0} max={50}
                        withLabel={true} suffix="%"
                        value={input.PercRecrFees} onChange={onChange('PercRecrFees')}
                    />
                </div>
                <div className="col value">{toPounds(ext.RecrFees)}</div>
            </div>
            <div className="row">
                <div className="col">
                    <Info comment="selection process, screening, IT set up, etc">
                        {avg('Onboarding')} costs
                    </Info>
                </div>
                <div className="col c">
                    <RangerInput
                        name='Onboarding'
                        min={0} max={10000}
                        withLabel={true} prefix="£"
                        value={input.Onboarding} onChange={onChange('Onboarding')}
                    />
                </div>
                <div className="col value">{toPounds(input.Onboarding)}</div>
            </div>
            <div className="row">
                <div className="col label">
                    Monthly desk space
                    {multiple ? <div className="comment">per employee</div>: null}
                </div>
                <div className="col c">
                    <RangerInput
                        name='DeskSpace'
                        min={0} max={10000}
                        withLabel={true} prefix="£"
                        value={input.DeskSpace} onChange={onChange('DeskSpace')}
                    />
                </div>
                <div className="col value">{toPounds(ext.DeskSpace)}</div>
            </div>
            <div className="row">
                <div className="col label">{multiple? 'Average equipment costs' : 'Equipment'}</div>
                <div className="col c">
                    <RangerInput
                        name='Equipment'
                        min={0} max={50000}
                        withLabel={true} prefix="£"
                        value={input.Equipment} onChange={onChange('Equipment')}
                    />
                </div>
                <div className="col value">{toPounds(ext.Equipment)}</div>
            </div>

            <div className="row-total">
                <div className="col-total l">
                    {multiple ?
                        'Average cost of an employee' :
                        'Total cost of employee'}
                    <br/>over the first year
                </div>
                <div className="col-total r">
                    {getMonth(12).map((m: any) => (toPounds(m.Cost)))}
                </div>
            </div>
            { multiple ? (
                <div className="row-multi-total">
                    <div className="col-total l">
                        Total cost of all {input.NumHire} employees
                        <br />over the first year
                    </div>
                    <div className="col-total r">
                        {getMonth(12).map((m: any) => (toPounds(input.NumHire*m.Cost)))}
                    </div>
                </div>
            ): null }

            <h3>Part 2: Employee ROI at different stages of their employment</h3>
            <h4>
                Employees go through different phases in their employment &mdash;
                adding little or no value at the start,
                to adding lots of value after being employed for some time.
            </h4>
            <p>
                Please use the sliders to adjust the length of time
                you expect your {multiple? 'employees' :'employee'} to be
                in each phase of employment
                - from Training, Integration, Value to Maximum Value and their
                expected ROI during each phase. The default phases below are
                based on averages discussed with our clients.
            </p>

            <div className="phases-row hdr">
                <div className="phases-col">Phases of Employment</div>
                <div className="phases-col">Length in Months</div>
                <div className="phases-col">Employment ROI over period</div>
            </div>
            <div className="phases-row">
                <div className="phases-col label">
                    <InfoPoint comment="This is the length of time
                    a new employee will be in training
                    and essentially delivering little or no ROI">
                        Phase One: Training
                    </InfoPoint>
                </div>
                <div className="phases-col">
                    <RangerInput
                        name='TrainMonth'
                        min={0} max={12}
                        withLabel={true}
                        value={input.TrainMonth} onChange={onChange('TrainMonth')}
                    />
                </div>
                <div className="phases-col">
                    <RangerInput
                        name='TrainPerc'
                        step={5}
                        min={0} max={300}
                        withLabel={true} suffix="%"
                        value={input.TrainPerc} onChange={onChange('TrainPerc')}
                    />
                </div>
            </div>
            <div className="phases-row">
                <div className="phases-col label">
                    <InfoPoint comment="This is the length of time a new employee
                            will be adapting to the new role and delivering little ROI">
                        Phase Two: Integration
                    </InfoPoint>
                </div>
                <div className="phases-col">
                    <RangerInput
                        name='IntegMonth'
                        min={0} max={12}
                        withLabel={true}
                        value={input.IntegMonth} onChange={onChange('IntegMonth')}
                    />
                </div>
                <div className="phases-col">
                    <RangerInput
                        name='IntegPerc'
                        step={5}
                        min={0} max={300}
                        withLabel={true} suffix="%"
                        value={input.IntegPerc} onChange={onChange('IntegPerc')}
                    />
                </div>
            </div>
            <div className="phases-row">
                <div className="phases-col label">
                    <InfoPoint comment="This is the length of time an employee starts
                            to add value to the business">
                        Phase Three: Value
                    </InfoPoint>
                </div>
                <div className="phases-col">
                    <RangerInput
                        name='ValueMonth'
                        min={0} max={12}
                        withLabel={true}
                        value={input.ValueMonth} onChange={onChange('ValueMonth')}
                    />
                </div>
                <div className="phases-col">
                    <RangerInput
                        name='ValuePerc'
                        step={5}
                        min={0} max={300}
                        withLabel={true} suffix="%"
                        value={input.ValuePerc} onChange={onChange('ValuePerc')}
                    />
                </div>
            </div>
            <div className="phases-row">
                <div className="phases-col label">
                    <InfoPoint comment="This is when an employee
                        adds maximum value and is
                        fully integrated into the business">
                        Phase Four: Maximum Value
                    </InfoPoint>
                </div>
                <div className="phases-col accent">ONGOING</div>
                <div className="phases-col">
                    <RangerInput
                        name='MaxValPerc'
                        step={5}
                        min={150} max={300}
                        withLabel={true} suffix="%"
                        value={input.MaxValPerc} onChange={onChange('MaxValPerc')}
                    />
                </div>
            </div>
        </EntryWrapper>
    );
}
