import React from 'react';
import styled from 'styled-components';

interface ILabelComment {
    children: any
    comment: String
}

const BtnInfo = styled.button`
width: 100%;
.label {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 24px;
}
.state-open svg .open { display: block; }
.state-open svg .hidden { display: none; }
.state-hidden svg .open { display: none; }
.state-hidden svg .hidden { display: block; }
.comment {
    width: calc(100% - 15px);
}

svg {
    display: inline;
    width: 20px; 
    height: 20px;  
    margin-left: 10px; 
    cursor: pointer;
    color: #444444;
}

.icon-left svg {
    margin-left: 0px;
    margin-right: 10px;
}
`;

export const Info: React.FC<any> = (props: ILabelComment) => { 
    const [state, setState] = React.useState(false);
    const onToggle = () => (setState(!state));
    return (
        <BtnInfo onClick={onToggle} className="info-wrapper">
            <div className={state ? 'state-open': 'state-hidden'}>
                <div className="label">
                    {props.children}
                    <svg
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" 
                        className="svg-inline--fa fa-info-circle fa-w-16 fa-2x"
                    >
                        <path
                            fill="currentColor" 
                            className="hidden"
                            d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" />
                        <path 
                            fill="currentColor" 
                            className="open"
                            d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" />
                    </svg>
                </div>
                {state? <div className="comment">{props.comment}</div>: null}
            </div>
        </BtnInfo>
    );
}

export const InfoPoint: React.FC<any> = (props: ILabelComment) => { 
    const [state, setState] = React.useState(false);
    const onToggle = () => (setState(!state));
    return (
        <BtnInfo onClick={onToggle} className="info-wrapper">
            <div className={state ? 'state-open': 'state-hidden'}>
                <div className="label icon-left">
                    <svg
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" 
                        className="svg-inline--fa fa-info-circle fa-w-16 fa-2x"
                    >
                        <path
                            fill="currentColor" 
                            className="hidden"
                            d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" />
                        <path 
                            fill="currentColor" 
                            className="open"
                            d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" />
                    </svg>
                    {props.children}
                </div>
                {state? <div className="comment">{props.comment}</div>: null}
            </div>
        </BtnInfo>
    );
}
