import React from 'react';
import './App.css';

import ScrollToTop from './ScrollToTop';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { Header, Footer } from './../components/Layout';
import { Intro, Individual, Multiple } from './../components/Pages';
import { Results } from './../components/Results';
import { useInputContext } from '../store';

const isIFrame = window.top !== window.self;
const style = isIFrame ? {} : { minHeight: '100vh' };

function App(props: any) {
  const { input, ext, output, onChange } = useInputContext('App')
  return (
    <div className="App container" style={style}>
      <HashRouter>
        <ScrollToTop />
        {/*<Header />*/}
        <Routes>
          <Route path='/' element={<Intro />} />
          <Route path='/individual' element={<Individual {...{ input, ext, output, onChange }}/>} />
          <Route path='/multiple' element={<Multiple {...{ input, ext, output, onChange }}/>} />
          <Route path='/individual/results' element={<Results multiple={false}  {...{ input, output }} />} />
          <Route path='/multiple/results' element={<Results multiple={true} {...{ input, output }} />} />
        </Routes>
      </HashRouter>
      {/*<Footer />*/}
    </div>
  );
}

export default App;
