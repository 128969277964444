import { MdmInput } from './types';
import { Debounced } from './Debounced';

const key = "MDM_v20220106";
export const MdmStorage = {
    get: (state: MdmInput): MdmInput => {
        try {
            const stateStr = sessionStorage.getItem(key);
            console.warn('MdmStorage.RESTORED', stateStr);
            return stateStr ? JSON.parse(stateStr) : state;
        } catch {
        }
        console.warn('MdmStorage.DEFAULT');
        return state;
    },
    set: (val: MdmInput) => {
        Debounced.start('key', () => {
            console.warn('MdmStorage.set', val);
            sessionStorage.setItem(key, JSON.stringify(val))
        }, 50);
        return val;
    }
};