import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Entry } from './Entry';
import { Submission } from './Submission';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-THSWQJ6'
}

TagManager.initialize(tagManagerArgs)

declare global {
    interface Window {
        FB: {
            CustomerChat: {
                hide: () => void;
                show: () => void;
            };
        };
    }
}

const IntroWrapper = styled.div`
    p {
        text-align: center;
        color: #46424a;
        max-width: 640px;
        margin: auto;
        margin-bottom: 1.25em;
        line-height: 1.2em;
        font-smooth: antialiased;
    }
    h3 {
        color: #46424a;
        text-align: center;
        text-transform: uppercase;
        font-size: 1em;
        font-smooth: antialiased;
    }
    .menu {
        display: flex;
        flex-direction:row;
        flex-wrap: wrap;
        padding: 20px; 
        max-width: 885px;
        margin: auto;
        margin-bottom: 50px;
    }
    .menu button.button,
    .menu a.button,
    .menu a.button:visited,
    .menu a.button:active {
        flex: 1;
        margin:20px; 
        user-select: none;
        cursor: pointer;
        border: none;

        display: block;
        padding: 20px 40px;
        background-color: #46424a;
        color: white;
        text-decoration: none;
    }
    .menu .button h4 {
        margin-top: 0.75em;
        margin-bottom: 0.33em;
        font-size: 1.66em;
        font-weight: 600;
        text-align: center;
    }
    .menu .button .comment {
        font-size: 1em;
        padding-top: 0.5em;
        padding-bottom: 0.33em;
        text-align: center;
    }
    @media(max-width: 885px){ 
        br { content: " "; }
        br:after { content: " "; }
    }
`;

export const Intro: React.FC<any> = () => {
    return (
        <IntroWrapper>
            <p>
                This calculator helps you understand the financial risks
                of new employees leaving in the early stages of employment
                and the benefits of retaining them in the long term.
            </p>
            <h3>
                There are two ways of using this calculator:
            </h3>
            <div className="menu">
                <Link onClick={() => { TagManager.dataLayer({ dataLayer: { event: 'individual_button_clicked' } })}} className="button" to='/individual'>
                    <h4>For individual employees</h4>
                    <div className="comment">
                        Calculating the ROI for one hire
                    </div>
                </Link>
                <Link onClick={() => { TagManager.dataLayer({ dataLayer: { event: 'multiple_button_clicked' } })}} className="button" to='/multiple'>
                    <h4>For multiple employees</h4>
                    <div className="comment">
                        Calculating the ROI for multiple hires
                        <br />
                        based on averages
                    </div>
                </Link>
            </div>
        </IntroWrapper>
    );
}

const IndividualWrapper = styled.div`
    .fw {
        background: #efedf0;
    }
    .cont {
        max-width: 885px;
        margin: 0px auto;
    }
    h2 { color: #ff7600; font-size:2em; padding-top: 60px; margin-bottom: 0px; }
    .intro {
        font-size: 1.2em;
    }
`;

export const Individual: React.FC<any> = (props: any) => {
    const { input, ext, output, onChange } = props;
    return (
        <IndividualWrapper>
            <div className="fw">
                <div className="cont">
                    <h2>For individual hires</h2>
                    <p className="intro">
                        Please use the sliders below to adjust the costs
                        associated with hiring your new employee.
                    </p>
                    <Entry multiple={false} {...{ input, ext, output, onChange }}/>
                </div>
            </div>
            <Submission multiple={false} {...{ input, onChange }} />
        </IndividualWrapper>
    );
}

const MultipleWrapper = styled.div`
    .fw {
        background: #efedf0;
    }
    .cont {
        max-width: 885px;
        margin: 0px auto;
    }
    h2 { color: #ff7600; font-size:2em; padding-top: 60px; margin-bottom: 0px; }
    .intro {
        font-size: 1.2em;
    }

    .row {
        display: flex;
        align-items: center;
    }
    .col {
        flex: 1;
        margin: 10px 0px;
    }
    .row .label {
        font-size: 1.33em;
        font-weight: 800;
        color: #666;
    }
`;

export const Multiple: React.FC<any> = (props: any) => {
    const { input, ext, output, onChange } = props;
    return (
        <MultipleWrapper>
            <div className="fw">
                <div className="cont">
                    <h2>For multiple hires</h2>
                    <p className="intro">
                        Please use the sliders below to adjust the costs
                        associated with hiring your new employees.
                    </p>
                    <Entry multiple={true} {...{ input, ext, output, onChange }} />
                </div>
            </div>
            <Submission multiple={true} {...{ input, onChange }} />
        </MultipleWrapper>
    );
}
