import React from 'react';
import styled from 'styled-components';
import { toPounds } from './../../services/Formats';
import { IChartAxisY } from './types';

const ChartAxisWrapper = styled.div`
  background: white;
  width: 120px;
  min-width: 120px; 

  .label {
    position: absolute;
    z-index: 1;
    left: 0px;
    width: 90px;
    border: none;
    text-align: right;
    font-size: 1.2em;
    font-weight: 800;
  }

  @media(max-width: 480px) {
    font-size: 0.5em;
  }
`;

const roundingStep = 1000;

export const ChartAxisY = (params: IChartAxisY) => {
  const shiftY =  15;
  const { height, maxValue } = params;
  // increase step by roundingStep until number of division is less than 500
  let step = roundingStep;
  //let divs = parseInt( maxValue / step, 10);
  let divs = Math.floor(maxValue / step);
  do {
    step += roundingStep
    //divs = parseInt( maxValue / step, 10);
    divs = Math.floor(maxValue / step);
  } while (divs > 9)
  const realHeight = height;
  const dy = realHeight / divs;
  return (
    <ChartAxisWrapper style={{ height: realHeight }}>
      {Array.from(Array(divs + 1).keys()).map(n => (
         <div key={n} className="label" style={{ top: dy * n - 9 + shiftY }}>
           {toPounds(Math.floor(maxValue - n * step))}
         </div>
      ))}
    </ChartAxisWrapper>
  );
};

export const getAxisY = (params: IChartAxisY) => {
    const { height, maxValue } = params;
    // increase step by roundingStep until number of division is less than 500
    let step = roundingStep;
    //let divs = parseInt( maxValue / step, 10);
    let divs = Math.floor(maxValue / step);
    do {
      step += roundingStep
      //divs = parseInt( maxValue / step, 10);
      divs = Math.floor(maxValue / step);
    } while (divs > 9)
    const dy = height / divs;
    return Array.from(Array(divs + 1).keys()).map(n => (dy * n));
};
