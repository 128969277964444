import React from 'react';

const Checkbox = (props) => {
  const isChecked = String(props.value) === 'true';
  const onChange = () => {
    props.onChange(!isChecked);
  };
  const styles = {
    forTop: { display: 'flex', alignItems: 'flex-start', cursor: 'pointer' },
    forLabel: { flex: 1, marginLeft: '15px', paddingTop: '5px', color: '#000', outline: 'none', userSelect: 'none' },
    forCheck: { display: 'flex', alignItems: 'center', textAlign: 'left', margin: '0px auto', outline: 'none', userSelect: 'none' },
    forButton: { stroke: '#000', strokeWidth: 1, strokeOpacity: 1 }
  };
  return (
    <div className='checkboxButton' style={styles.forTop}>
      <label
        role='checkbox'
        aria-checked='false'
        tabIndex={0}
        style={styles.forCheck}
        onMouseDown={onChange}
      >
        <svg viewBox='0 0 20 20' style={{ width: 30, height: 30 }}>
          <rect x='0' y='0' width='20' height='20' fill='transparent' style={styles.forButton} />
          {isChecked && (<polygon fill='#666' points='7.6,11.6 4.4,8.4 2,10.8 7.6,16.4 18,6 15.6,3.6'></polygon>)}
        </svg>
      </label>
      {props.title ? (
        <div
          role='checkbox'
          aria-checked='false'
          tabIndex={0}
          onMouseDown={onChange}
          style={styles.forLabel}
          dangerouslySetInnerHTML={{__html: props.title}}
        >
        </div>
      ) : ''
      }
    </div>
  );
};

export default Checkbox;
