import React from 'react';
import styled from 'styled-components';
import ContainerDimensions from 'react-container-dimensions'
import { MdmOutput, MdmMonthlyOutput } from './../../services/types';
import { toPounds, roundedTo } from './../../services/Formats';
import { ChartAxisY, getAxisY } from './ChartAxisY';
import { Tooltip } from 'react-svg-tooltip';

const GraphWrapper = styled.div`
width: 100%;
display: flex;
position: relative;
padding-top: 20px;

.inner {
    flex: 1;
}
.inner svg .area {
    fill: #aaa;
    opacity: 0;
}
.inner svg .area:hover {
    opacity: 0.1;
}
.inner svg .area.reverted {
    left: -200px;
}
.inner svg .text {
    font-size: 50px;
    font-weight: 600;
    width: 380px;
    fill: white;
}
.inner svg .tooltip {
    width: 300px;
    height: 150px;
}
.inner svg .minus.tooltip {
    fill: #ff1100;
}
.inner svg .plus.tooltip {
    fill: #01860a;
}

.axis-x {
    display: flex;
}
.axis-x .label {
    flex: 1;
    font-weight: 800;
    color: #223c5d;
}
`;

interface GraphProperties {
    output: MdmOutput;
}

export const Graph = (props: GraphProperties) => {
    const { output } = props;
    const getMI = () => {
      return output.Months.filter((m: MdmMonthlyOutput) => (m.Index > 1));
    };
    const getMaxValue = (): number => {
        return output.Months.reduce((currentValue: number, m: MdmMonthlyOutput) => {
            const val = Math.max(m.Cost, m.Payback)
            return (val > currentValue) ? val : currentValue;
        }, 0);
    };

    const maxValue = roundedTo(getMaxValue() + 20000, 10000);
    return (
        <ContainerDimensions>
            { ({ width }) => {
                const svgWidth = width - 120;
                const svgHeight = svgWidth * 0.4;
                const iWidth = 2500;
                const iHeight = 1000;
                const dx = iWidth / 35.5;
                const dy = iHeight / maxValue;
                return (
                    <GraphWrapper id="graph">
                        <ChartAxisY maxValue={maxValue} height={svgHeight} />
                        <div className="inner">
                            <svg
                                viewBox={`0 0 ${iWidth} ${iHeight}`}
                                style={{ width: svgWidth, height: svgHeight }}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                {getAxisY({ maxValue, height: iHeight }).map(y => (
                                    <path key={y} d={`M0 ${y} L${iWidth - 1} ${y}`} fill="#000" stroke="#000" />
                                ))}
                                {output.Months.map(m => (
                                    <circle
                                        key={m.Index}
                                        cx={`${15 + dx * (m.Index - 1)}`}
                                        cy={`${iHeight - 15 - dy * m.Cost}`}
                                        r="15" fill="#46424a" />
                                ))}
                                {getMI().map((m, index) => (
                                    <line 
                                        key={m.Index}
                                        x1={`${15 + dx * (m.Index - 1)}`}
                                        y1={`${iHeight - 15 - dy * m.Cost}`}
                                        x2={`${15 + dx * (m.Index - 2)}`}
                                        y2={`${iHeight - 15 - dy * output.Months[index].Cost}`}
                                        strokeWidth="5"
                                        stroke="#46424a" />
                                ))}
                                {output.Months.map(m => (
                                    <circle
                                        key={m.Index}
                                        cx={`${15 + dx * (m.Index - 1)}`}
                                        cy={`${iHeight - 15 - dy * m.Payback}`}
                                        r="15" fill="#ff7600" />
                                ))}
                                {getMI().map((m, index) => (
                                    <line
                                        key={m.Index}
                                        x1={`${15 + dx * (m.Index - 1)}`}
                                        y1={`${iHeight - 15 - dy * m.Payback}`}
                                        x2={`${15 + dx * (m.Index - 2)}`}
                                        y2={`${iHeight - 15 - dy * output.Months[m.Index - 2].Payback}`}
                                        strokeWidth="5"
                                        stroke="#ff7600" />
                                ))}
                            
                                <line
                                    key={output.BreakevenMonth}
                                    x1={15 + dx * (output.BreakevenMonth - 1)}
                                    y1={0}
                                    x2={15 + dx * (output.BreakevenMonth - 1)}
                                    y2={iHeight}
                                    strokeWidth="10"
                                    stroke="#ff1100" />
                            

                                {output.Months.map((m, index) => {
                                    const cls = ((m.Total > 0) ? 'plus' : 'minus' );
                                    const prefix = (m.Total > 0) ? 'Gain' : 'Loss';
                                    const offs = (index > 20) ? -300 : 0;
                                    // const txt = prefix + ' of ' + toPounds(Math.abs(m.Total));
                                    const ref = React.createRef<SVGRectElement>();
                                    return (
                                        <g key={m.Index} className={`area ${cls}`}>
                                            <rect
                                                className={`line ${cls}`}
                                                ref={ref}
                                                x={dx * (m.Index - 1)}
                                                y={0}
                                                width={dx * 0.4}
                                                height={iHeight}
                                            />
                                            <Tooltip triggerRef={ref}>
                                                <rect x={offs} y={0} className={`tooltip ${cls}`} />
                                                <text x={offs+50} y={50} className={`text ${cls}`} >
                                                    {prefix} of
                                                </text>
                                                <text x={offs+50} y={120} className={`text ${cls}`}>
                                                    {toPounds(Math.abs(m.Total))}
                                                </text>
                                            </Tooltip>
                                        </g>
                                    )
                                })}
                            </svg>
                            <div className="axis-x">
                                {Array.from(Array(output.Months.length).keys()).map(n => (
                                    <div key={n} className="label">{n + 1}</div>
                                ))}
                            </div>
                        </div>
                    </GraphWrapper>
                );
            }
            }
        </ContainerDimensions>
    );
}